'use client';

import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { SimpleLayout } from 'src/layouts/simple';
import { MaintenanceIllustration } from 'src/assets/illustrations';

// ----------------------------------------------------------------------

export function MaintenanceView() {
    const router = useRouter();

    useEffect(() => {
        const checkStatus = async () => {
            try {
                const response = await fetch(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/config/unauth`, {
                    headers: {
                        'Cache-Control': 'no-store',
                        'Referer': process.env.NEXT_PUBLIC_FRONTEND_URL
                    }
                });

                if (response.status === 200) {
                    router.push('/');
                }
            } catch (error) {
                console.error('Erro ao verificar status:', error);
            }
        };

        // Verifica o status a cada 30 segundos
        checkStatus();
        const interval = setInterval(checkStatus, 30000);

        return () => clearInterval(interval);
    }, [router]);

    return (
        <SimpleLayout content={{ compact: true }}>
            <Container>
                <Typography variant="h3" sx={{ mb: 2 }}>
                    Em manutenção
                </Typography>

                <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                    O sistema está passando por manutenção no momento. Por favor, tente novamente mais tarde.
                </Typography>

                <MaintenanceIllustration sx={{ my: { xs: 5, sm: 10 } }} />
            </Container>
        </SimpleLayout>
    );
} 